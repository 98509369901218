<template>
  <v-dialog
    max-width="500"
    scrollable
    v-model="modal"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        small
        icon
        class="mr-2"
      ><v-icon small>mdi-cog-outline</v-icon></v-btn>
    </template>

    <v-card>
      <v-card-title>{{$t('notification.title_setting')}}</v-card-title>

      <v-card-text>
        <div v-for="(item, key) in opUserSettingsJson.system_notifications" :key="key">
          <v-checkbox

            :input-value="item"
            @change="changeOption($event, key)"
            :label="getOptionLabel(key)"
            hide-details
            :false-value="false"
            :true-value="true"

          />
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn
          class="mt-2"
          color="primary"
          elevation="0"
          @click="saveOperatorSettings"
        >{{$t('modules.op.common.save')}}</v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
  name: 'MenuNotificationsSettings',

  data: () => ({
    modal: false,
  }),

  computed: {
    ...mapGetters([
      'opUserSettingsJson',
    ]),
  },

  methods: {
    ...mapActions([
      'OpAxiosSaveUserSettings',
    ]),

    changeOption(val, key) {
      this.$store.state.kwizbot.operator_panel
        .userSettingsJson.system_notifications[key] = val
    },

    async saveOperatorSettings() {
      await this.OpAxiosSaveUserSettings();

      this.modal = false
    },

    getOptionLabel(key) {
      let label = 'new option'

      switch (key) {
        case 'chat_room_created':
          label = this.$t('modules.op.texts.new_dialog')
          break;
        case 'new_message':
          label = this.$t('modules.op.texts.new_message')
          break;
        case 'participants_connected':
          label = this.$t('modules.op.texts.participant_added')
          break;
        case 'chat_room_closed':
          label = this.$t('modules.op.texts.chat_closed')
          break;
        default:
          break;
      }

      return label
    }
  },
}
</script>