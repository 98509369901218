import moment from 'moment'
import { appStore } from "@/main";

export default {
    install(Vue, options) {


        Vue.prototype.$getFloatValue = function (val) {

            val = parseFloat(val);
            val = val.toFixed(2);

           if(isNaN(val)) return 0;

            return val;

        }

        Vue.prototype.$dateFormat = function (value) {
            if (value) {
                return moment(String(value)).format('YYYY-MM-DD')
            }
        }

      Vue.prototype.$dateFormatDots = function (value) {
        if (value) {
          return moment(String(value)).format('DD.MM.YYYY')
        }
      }

        Vue.prototype.$dateTimeFormat = function (value) {
            if (value) {
                return moment(String(value)).format('DD.MM.YYYY HH:mm')
            }
        }

			Vue.prototype.$dateTimeFormatWithDash = function (value) {
				if (value) {
					return moment(String(value)).format('YYYY-MM-DD-HH-mm')
				}
			}

        Vue.prototype.$getCookie = function(name){

            let matches = document.cookie.match(new RegExp(
                "(?:^|; )" + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + "=([^;]*)"
            ));
            return matches ? decodeURIComponent(matches[1]) : undefined;
        }

        Vue.prototype.$setCookie = function(name, value, options = {}){
            //$setCookie('key', 'value', {'expires': 10*24*60*60*1000});

            options = {
                path: '/',
                ...options
            };

            if (options.expires && options.expires.toUTCString) {
                options.expires = options.expires.toUTCString();
            }

            let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

            for (let optionKey in options) {
                updatedCookie += "; " + optionKey;
                let optionValue = options[optionKey];
                if (optionValue !== true) {
                  updatedCookie += "=" + optionValue;
                }
            }

            document.cookie = updatedCookie;
        }

        Vue.prototype.$deleteCookie = function(name){
            this.$setCookie(name, "", {
                'max-age': -1
            })
        }

        Vue.prototype.$forceFileDownload = function(response, title){
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', title)
            document.body.appendChild(link)
            link.click()
            link.remove()
        }

				Vue.prototype.$consoleLogHandler = function(data){
					let enabled = false

					const getSettings = function() {
						if (
							appStore.getters?.get_instance_settings.length &&
							appStore.getters?.get_instance_settings?.find((s) => s.key === 'console_log_enabled')
						) {
							enabled = appStore.getters?.get_instance_settings
								?.find((s) => s.key === 'console_log_enabled').value === '1' || false
						}
					}

					if(!appStore.getters?.get_instance_settings.length) {
						setTimeout(() => {
							getSettings();
							if (enabled) console.log(data)
						}, 2000)
					} else {
						getSettings();
						if (enabled) console.log(data)
					}
        }
    }
}
