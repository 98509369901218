<template>
  <v-progress-circular
    v-if="getter_ajax_dialog.show && getter_ajax_dialog.loading"
    indeterminate
    color="primary"
    class="preloader-progress"
  ></v-progress-circular>
</template>

<script>
import { mapGetters } from 'vuex';

export default{
    name: "AxiosPreloaderAndResponse",
    props: [],

  data: () => ({
    toastOptions: {
      position: 'top-center',
      timeout: 4000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      closeButton: 'button',
      icon: true,
    },
  }),

  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if(
        mutation.type === 'UPDATE_AJAX_DIALOG'
        && this.getter_ajax_dialog?.show
        && !this.getter_ajax_dialog?.loading
      ) this.showToast()
    });
  },

  destroyed() {
    this.unsubscribe()
  },

  computed: {
    ...mapGetters({
      getter_ajax_dialog: 'ajax_dialog',
      notificatoins: 'getNotifications',
      opUserSettingsJson: 'opUserSettingsJson',
    }),
  },

  methods: {
    showToast() {
      const val = this.getter_ajax_dialog
      this.$toast.clear()

      //Preparing Content
      let myJSX;
      if(val?.title) {
        myJSX = (
          <div>
            <h4><b>{val?.title}</b></h4>
            <div>{val?.description}</div>
          </div>
        );
      } else {
        myJSX = ( <div>{val?.description}</div> );
      }
      //end Preparing Content

      if(val?.error && val?.description) {
        this.$toast.error(myJSX, { ...this.toastOptions });
      } else {
        let show = true
        const sys_notif_settings = this.opUserSettingsJson.system_notifications

        switch (val?.description) {
          case this.$t('modules.op.texts.new_dialog'):
            show = sys_notif_settings.chat_room_created
            break;
          case this.$t('modules.op.texts.new_message'):
            show = sys_notif_settings.new_message
            break;
          case this.$t('modules.op.texts.participant_added'):
            show = sys_notif_settings.participants_connected
            break;
          case this.$t('modules.op.texts.chat_closed'):
            show = sys_notif_settings.chat_room_closed
            break;

          default:
            break;
        }

        if(show)
          this.$toast(myJSX, { ...this.toastOptions, ...val?.options });
      }

      const date = new Date()

      this.$store.dispatch('setNotification', {
        type: val?.error ? 'error' : 'info',
        title: val?.title,
        text: val.description,
        date: date.toISOString(),
        read: false,
        message_type: val?.message_type,
      })

      //Save notifications to storage
      localStorage.setItem(
        'kw_notifications',
        JSON.stringify(this.notificatoins),
      )
      //end Save notifications to storage
    },
  },

  watch: {
    // getter_ajax_dialog_disabled: {
    //   handler(val) {
    //
    //     if(val?.show && !val?.loading) {
    //       //clear previous toast
    //       this.$toast.clear()
    //
    //       //Preparing Content
    //       let myJSX;
    //       if(val?.title) {
    //         myJSX = (
    //           <div>
    //             <h4><b>{val?.title}</b></h4>
    //             <div>{val?.description}</div>
    //           </div>
    //         );
    //       } else {
    //         myJSX = ( <div>{val?.description}</div> );
    //       }
    //       //end Preparing Content
    //
    //       if(val?.error && val?.description) {
    //         this.$toast.error(myJSX, { ...this.toastOptions });
    //       } else {
    //         this.$toast.info(val.description, { ...this.toastOptions });
    //       }
    //     }
    //   },
    //   deep: true,
    //
    // },
  },
}
</script>

<style scoped lang="scss">
  .preloader-progress {
    position: absolute;
    bottom: 50px;
    left: 50%;
    right: 0;
    z-index: 10000;
    transform: translate(-50%, 0);

  }
    .text-center{
        text-align: center !important;
    }
</style>